import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import * as Icon from 'react-feather'
import ReactGA from 'react-ga'
import LazyLoad from 'react-lazyload'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Props as ReviewProps, Review } from './Review'

SwiperCore.use([Autoplay, Pagination])

export interface Props {
  buttonURL?: string
  customerReviews?: ReviewProps[]
  email?: string
  facebookURL?: string
  label?: string
  languageCode: string
  imageLeft?: string
  imageRight?: string
  instagramURL?: string
  phone?: string
  title?: string
}

export const AboutUs = memo(function AboutUs({
  buttonURL,
  customerReviews,
  email,
  facebookURL,
  label,
  languageCode,
  imageLeft,
  imageRight,
  instagramURL,
  phone,
  title,
}: Props) {
  return (
    <Container>
      {label ? (
        <FadeInOutAnimation>
          <Label>{label}</Label>
        </FadeInOutAnimation>
      ) : null}
      {title ? (
        <FadeInOutAnimation>
          <Title>{title}</Title>
        </FadeInOutAnimation>
      ) : null}
      <FadeInOutAnimation>
        <Grid dial={2} row>
          <Left>
            {imageLeft ? (
              <LazyLoad>
                <BackgroundImage
                  style={{ backgroundImage: `url(${imageLeft})` }}
                />
              </LazyLoad>
            ) : null}
            <Wrapper>
              <WrapperTitle>
                {useVocabularyData('reservations', languageCode)}
              </WrapperTitle>
              {phone ? (
                <ContactsItem
                  href={`tel:${phone}`}
                  onClick={() => {
                    if (isMobile) {
                      ReactGA.event({
                        category: 'Website',
                        action: 'Click',
                        label: 'Mobile Phone',
                      })
                    }
                  }}
                >
                  {phone}
                </ContactsItem>
              ) : null}
              {email ? (
                <ContactsItem
                  href={`mailto:${email}`}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Website',
                      action: 'Click',
                      label: 'Email address',
                    })
                  }}
                >
                  {email}
                </ContactsItem>
              ) : null}
              <CTA
                label={useVocabularyData('ask', languageCode)}
                URL={buttonURL}
              />
            </Wrapper>
          </Left>
          <Center>
            <svg viewBox="0 0 56.496 37.301">
              <path
                d="M15638.906,32.758a3.2,3.2,0,0,1,3.176-3.237,16.005,16.005,0,0,0,10.119-3.614c-.07,0-.176.014-.246.014a12.923,12.923,0,0,1-12.834-12.963A12.735,12.735,0,0,1,15651.73,0c7.047,0,12.463,5.8,12.463,12.954v0c0,12.706-9.885,23.042-22.391,23.042A2.978,2.978,0,0,1,15638.906,32.758Zm-29.908,0a3.208,3.208,0,0,1,3.182-3.237,16,16,0,0,0,10.117-3.614c-.068,0-.172.014-.244.014a12.922,12.922,0,0,1-12.83-12.963,12.557,12.557,0,1,1,25.1,0v0c0,12.706-9.9,23.042-22.41,23.042A2.993,2.993,0,0,1,15609,32.758Z"
                transform="translate(-15608.348 0.65)"
                strokeMiterlimit="10"
                strokeWidth="1.3"
              />
            </svg>
            <Reviews>
              {customerReviews ? (
                <>
                  <Swiper
                    allowTouchMove={false}
                    autoplay={{ delay: 4000 }}
                    loop
                    pagination={
                      customerReviews.length > 1
                        ? {
                            clickable: true,
                          }
                        : false
                    }
                  >
                    {customerReviews.map((item, index) => (
                      <SwiperSlide key={index}>
                        <Review {...item} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              ) : null}
            </Reviews>
          </Center>
          <Right>
            {imageRight ? (
              <LazyLoad>
                <BackgroundImage
                  style={{ backgroundImage: `url(${imageRight})` }}
                />
              </LazyLoad>
            ) : null}
            <Wrapper>
              <WrapperTitle>
                {useVocabularyData('follow-us', languageCode)}
              </WrapperTitle>
              <Social dial={5} row>
                {facebookURL ? (
                  <SocialItem
                    aria-label="Facebook"
                    href={facebookURL}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Icon.Facebook />
                  </SocialItem>
                ) : null}
                {instagramURL ? (
                  <SocialItem
                    aria-label="Instagram"
                    href={instagramURL}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Icon.Instagram />
                  </SocialItem>
                ) : null}
              </Social>
            </Wrapper>
          </Right>
        </Grid>
      </FadeInOutAnimation>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.75rem auto;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
  }

  @media (max-width: 767px) {
    margin: 5.25rem 0;
  }
`

const Label = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 0.9375rem;
  opacity: 0.4;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    height: 2px;
  }
  &:before {
    margin-right: 1.5rem;
  }
  &:after {
    margin-left: 1.5rem;
  }
`

const Grid = styled(FlexBox)`
  width: 100%;
  max-width: 73.5rem;
  margin: 3.75rem auto 0;
  padding: 0 1.5rem;

  @media (max-width: 767px) {
    display: block;
  }
`

const BoxStyle = css`
  width: 33.333%;
  height: 23.375rem;
  background: ${theme.colors.variants.neutralLight3};
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
`

const Left = styled.div`
  ${BoxStyle}
`

const Center = styled.div`
  width: 33.333%;
  height: 23.375rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  margin: 0 2px;
  padding: 2.5rem 3.75rem;
  position: relative;
  svg {
    width: auto;
    height: 50px;
    fill: none;
    stroke-width: 0.7;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
  }

  @media (max-width: 1199px) {
    padding: 2.25rem 1.5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    margin: 0;
  }
`

const Right = styled.div`
  ${BoxStyle}

  @media (max-width: 767px) {
    margin-top: 2.25rem;
  }
`

const BackgroundImage = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 4.625rem 3.375rem 0;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  bottom: 2.5rem;
  left: 2.5rem;
  z-index: 2;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.5rem 0;
  }

  @media (max-width: 767px) {
    padding: 0;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
`

const WrapperTitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
`

const ContactsItem = styled.a`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.875rem;
  margin-top: 0.375rem;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1023px) {
    white-space: normal;
    word-wrap: break-word;
  }
`

const CTA = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 767px) {
    margin: 2.25rem auto;
    position: relative;
    left: auto;
    transform: none;
  }
`

const Social = styled(FlexBox)``

const SocialItem = styled.a`
  margin: 0 0.5625rem;
  svg {
    stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
    height: 1.5rem;
    transition: 0.3s ease-out;
  }
  &:hover {
    svg {
      fill: ${({ theme }) =>
        theme.colors.getHoverColor(`${theme.colors.variants.primaryDark1}`)};
    }
  }
`

const Reviews = styled.div`
  margin-top: 1.125rem;

  .swiper-container {
    display: flex;
    flex-wrap: wrap;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    order: 2;
    margin-top: 3.75rem;
    position: relative;
    bottom: 0;
    span {
      width: 3rem;
      height: 0.1875rem;
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      border-radius: 0;
      margin: 0 0.3125rem;
      opacity: 0.2;
      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
`
